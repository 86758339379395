<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!esign">
      <span class="loader"></span>
    </span>
    <package-editor
      v-if="esign"
      :package-url="esign.edition_url"
    />
  </div>
</template>

<script>
import PackageEditor from "../partials/PackageEditor.vue";

export default {
  layout: "DashboardLayout",

  components: { PackageEditor },

  props: {
    esignId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      esign: null,
      formErrors: null,
      loading: false,
    };
  },

  created() {
    this.get();
  },

  methods: {
    async get() {
      this.loading = true;
      try {
        await this.$store.dispatch("esigns/get", `${this.esignId}_include_edition_url`);
        this.esign = this.$store.getters["esigns/esign"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.loading = false;
      }
    },
  },
};
</script>
