<template>
  <div class="elite-tabs-wrapper-content">
    <log-list-table :filterSubject="esign" />
  </div>
</template>
<script>
import LogListTable from "@/views/Pages/DefaultModule/LogManagement/partials/LogListTable.vue";

export default {
  name: "esign-view-logs",
  components: { LogListTable },
  props: ["esign"],
  watch: {
    user(user) {},
  },
};
</script>
