<template>
    <div>
      <iframe
        :src="iframeSrc"
        width="100%"
        height="600px"
        frameborder="0"
        allowfullscreen
      ></iframe>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      packageUrl: {
        type: String,
        required: true
      }
    },
    computed: {
      iframeSrc() {
        return this.packageUrl;
      }
    }
  };
  </script>
  
  <style scoped>
  iframe {
    border: 0;
    height: 80vh;
  }
  </style>
  