<template>
    <badge :type="badgeType">
      {{ $t('ESIGNS.'+status) }}
    </badge>
  </template>
  
  <script>
  import {
    STATUS_DRAFT,
    STATUS_SENT,
    STATUS_COMPLETED,
    STATUS_DECLINED,
    STATUS_EXPIRED,
  } from "@/constants/common";
  
  export default {
    name: "StatusBadge",
    props: {
      status: {
        type: String,
        required: true,
        validator: function (value) {
          return [
            STATUS_DRAFT,
            STATUS_SENT,
            STATUS_COMPLETED,
            STATUS_DECLINED,
            STATUS_EXPIRED,
          ].includes(value);
        },
      },
    },
    computed: {
      badgeType() {
        switch (this.status) {
          case STATUS_DRAFT:
            return "secondary";
          case STATUS_SENT:
            return "primary";
          case STATUS_COMPLETED:
            return "success";
          case STATUS_DECLINED:
            return "danger";
          case STATUS_EXPIRED:
            return "warning";
          default:
            return "default";
        }
      },
    },
  };
  </script>